import { navigate } from "gatsby";
import React from "react";
import { FiArrowLeft } from "react-icons/fi";

import Layout from "../components/layout";
import SEO from "../components/seo";
import abductionIllustration from "../images/abduction-illustration.svg";
import LogoYellow from '../images/logo-yellow.svg';

function NotFoundPage() {
  return (
    <div className="flex flex-col h-screen w-screen items-center justify-center">
      <LogoYellow className="mb-2 w-[150px]" />
      <h3 className="text-lg font-bold mb-4">404 Not Found</h3>
      <h3 className="font-bold mb-4">You seemed lost</h3>
      <button onClick={() => navigate('/')} className="btn btn-primary"><FiArrowLeft className="mr-2" />Go to Homepage</button>
    </div >
  );
}

export default NotFoundPage;
