import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCHkxE1y1JaNsiuQUIeV0V-Pnl46c-ZLp0',
  authDomain: 'pinbuggy-cc236.firebaseapp.com',
  databaseURL: 'https://pinbuggy-cc236.firebaseio.com',
  projectId: 'pinbuggy-cc236',
  storageBucket: 'pinbuggy-cc236.appspot.com',
  messagingSenderId: '768642251105',
  appId: '1:768642251105:web:5bb66e4d902d32c22eed20',
  measurementId: 'G-KK6CJEEZVJ',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const rtdb = getDatabase();

export {
  app, db, auth, rtdb,
};
